<template>
  <b-container id="saml-connection-view" class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h3>Edit SAML Connection Fields</h3>
        <router-link :to="{ name: 'SamlCoreConnectionList' }" class="d-flex justify-content-left align-items-center">
          <b-icon icon="arrow-left" aria-label="Back to connection list"/>
          Back to List
        </router-link>
      </b-col>
    </b-row>
    <div v-if="loading" class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <div class="spinner spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <template v-else>
      <b-row class="mt-4">
        <b-col>
          <b-form-group
            label="Connection Key"
            label-for="connection-key">
            <b-form-input
              id="connection-key" type="text" :disabled="true"
              :value="connection.key"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Partner Key"
            label-for="partner-key">
            <b-form-input
              id="partner-key" type="text" :disabled="true"
              :value="connection.core_partner_key"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-card title="Connection Fields">
            <b-table
              :fields="cols" :items="fields" :busy="loadingFields"
              outlined sticky-header="800px" striped small fixed
              empty-text="No connections to show">

              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(name)="row">
                <b-icon v-if="row.item.is_core" title="Core Field" font-scale="1" icon="lock" aria-label="Core Field"/>
                <b-form-input v-if="editingIndex === row.index && row.item.is_custom"
                  type="text" v-model="row.item.name"/>
                <span v-else>{{ row.item.name }}</span>
              </template>

              <template #cell(map_from)="row">
                <b-input-group>
                  <b-form-input type="text"
                    v-model="row.item.map_from" :disabled="editingIndex !== row.index"/>

                  <b-input-group-append>
                    <template v-if="editingIndex !== row.index">
                      <b-button size="sm" variant="primary" :disabled="isLockedForEditing(row.index)" @click="toggleEdit(row.index)">
                        <b-icon icon="pencil" aria-label="Help"></b-icon>
                      </b-button>
                      <b-button size="sm" variant="danger" :disabled="isLockedForEditing(row.index)"
                        v-if="!!row.item.uuid" @click="deleteField(row.index)">
                        <b-icon icon="trash" aria-label="Help"></b-icon>
                      </b-button>
                    </template>
                    <template v-else>
                      <b-button size="sm" variant="danger" @click="cancelEdit(row.index)">
                        <b-icon icon="x-lg" aria-label="Help"></b-icon>
                      </b-button>
                      <b-button size="sm" variant="success" @click="saveField(row.index)">
                        <b-icon icon="check-lg" aria-label="Help"></b-icon>
                      </b-button>
                    </template>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import { get as getConnection } from '@/api/saml/core/connections';
import {
  list as getConnectionFields,
  create as createConnectionField,
  edit as editConnectionField,
  remove as deleteConnectionField,
} from '@/api/saml/core/connection_fields';

export default {
  name: 'SamlCoreConnectionEdit',
  beforeMount() {
    this.loading = true;

    getConnection(this.$route.params.key)
      .then(connection => {
        this.connection = connection;

        this.listConnectionFields(this.connection.key);
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to get connection: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: false,
      loadingFields: false,
      connection: {},
      fields: [],
      editingIndex: -1,
      deletingIndex: -1,
      cols: [
        { key: 'name', label: 'Name' },
        { key: 'map_from', label: 'Map From' },
      ],
    };
  },
  methods: {
    isLockedForEditing(index) {
      return (this.editingIndex !== -1 && this.editingIndex !== index) || this.deletingIndex !== -1;
    },
    toggleEdit(index) {
      this.editingIndex = index;
    },
    cancelEdit(index) {
      delete this.fields[index].new_map_from;
      this.fields = this.fields.slice(); // force array mutation
      this.editingIndex = -1;
    },
    deleteField(index) {
      // disable linting on purpose here we want an obtrusive alert
      // eslint-disable-next-line no-alert
      const res = window.confirm('Are you sure you want to delete?');
      if (!res) {
        return;
      }

      this.deletingIndex = index;
      deleteConnectionField(this.connection.key, this.fields[index].uuid)
        .then(this.listConnectionFields)
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to delete connection field: ${err}`);
        });
    },
    saveField(index) {
      const isEdit = !!this.fields[index].uuid;
      if (isEdit) {
        this.editField(index);
      } else {
        this.createField(index);
      }
    },
    editField(index) {
      const isCustom = this.fields[index].is_custom;
      const data = {
        map_from: this.fields[index].map_from,
      };

      if (isCustom) {
        data.custom_name = this.fields[index].name;
      }

      editConnectionField(this.connection.key, this.fields[index].uuid, data)
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to edit connection field: ${err}`);
        })
        .finally(this.listConnectionFields);
    },
    createField(index) {
      const isCore = this.fields[index].is_core;
      const isCustom = this.fields[index].is_custom;
      const data = {
        map_from: this.fields[index].map_from,
      };

      if (isCore) {
        data.override_core_field_name = this.fields[index].name;
      } else if (isCustom) {
        data.custom_name = this.fields[index].name;
      }

      createConnectionField(this.connection.key, data)
        .then(uuid => {
          this.fields[index].uuid = uuid;
          this.fields[index].map_from = data.map_from;
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to create connection field: ${err}`);
        })
        .finally(this.listConnectionFields);
    },
    listConnectionFields() {
      this.editingIndex = -1;
      this.deletingIndex = -1;
      this.loadingFields = true;
      getConnectionFields(this.connection.key)
        .then(fields => R.sortWith([
          R.descend(R.prop('is_core')),
          R.ascend(R.prop('name')),
          R.descend(R.prop('is_custom')),
        ])(fields))
        .then(fields => {
          this.fields = R.append({
            is_core: false,
            is_custom: true,
            is_override: false,
            map_from: '',
            name: '',
            uuid: '',
          }, fields);
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to get connection fields: ${err}`);
        })
        .finally(() => {
          this.loadingFields = false;
        });
    },
  },
};
</script>
