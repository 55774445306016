import axios from 'axios';

export function list(key) {
  return axios.get(`/v1/saml/core/connections/${key}/fields`)
    .then(({ data }) => data);
}

export function create(connectionKey, data) {
  return axios.post(
    `/v1/saml/core/connections/${connectionKey}/fields`,
    data,
  )
    .then(({ data: { uuid } }) => uuid);
}

export function edit(connectionKey, fieldUUID, data) {
  return axios.patch(
    `/v1/saml/core/connections/${connectionKey}/fields/${fieldUUID}`,
    data,
  )
    .then(() => {});
}

export function remove(connectionKey, fieldUUID, data) {
  return axios.delete(
    `/v1/saml/core/connections/${connectionKey}/fields/${fieldUUID}`,
    data,
  )
    .then(() => {});
}

export default {};
